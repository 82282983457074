import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Loadable from "@loadable/component"
import ClipLoader from "react-spinners/ClipLoader";
// import "../components/HomePageServiceSection/HomePageServiceSection.scss"
import Layout from "../components/Layout/Layout"
import HomePageHeader from "../components/HomePageHeader/HomePageHeader"
import HomePageBrochure from "../components/HomePageBrochure/HomePageBrochure"
import HomePageHeaderAdvertisement from "../components/HomePageHeaderAdvertisement/HomePageHeaderAdvertisement"
import HomePageConfigSection from "../components/HomePageConfigSection/HomePageConfigSection"
import { useAllVideos } from "../hooks/Videos/useAllVideos"
const withSpinner = (importComponent) => Loadable(importComponent, {
  fallback: <ClipLoader color="#2e41ff" size={150} cssOverride={{ display: 'block', margin: '50px auto', borderWidth: '4px' }} aria-label="Loading Spinner" />,
});
const SeoPage = Loadable(() => import("../components/seo-page"))
const HomePageOppportunitiesSection = withSpinner(() => import("../components/HomePageOpportunitiesSection/HomePageOpportunitiesSection"));
const VideoPageListing = withSpinner(() => import("../components/VideoPageListing/VideoPageListing"));
const HomepageNewServiceSection = withSpinner(() => import("../components/HomePageNewServiceSection/HomePageNewServiceSection"));
const ArticlesSection = withSpinner(() => import("../components/ArticlesSection/ArticlesSection"));
const GoogleReviewsSection = withSpinner(() => import("../components/GoogleReviewsSection/GoogleReviewsSection"));
const FaqSection = withSpinner(() => import("../components/FaqSection/FaqSection"));
const PartnersSection = withSpinner(() => import("../components/PartnersSection/PartnersSection"));
const Footer = withSpinner(() => import("../components/Footer/Footer"));
const PopUp = withSpinner(() => import("../components/PopUp/PopUp"));
const CasaVerdePopup = withSpinner(() => import("../components/CasaVerdePopup/CasaVerdePopup"));

const IndexPage = ({ location }) => {
  const videos = useAllVideos()
  const { i18n } = useTranslation()
  const { language } = i18n
  const seoFields = {
    keyword: {
      en: "Ensys",
      ro: "Ensys",
    },
    slug: {
      en: "Panouri Fotovoltaice – Soluții Complete",
      ro: "Panouri Fotovoltaice – Soluții Complete",
    },
  }

  function getSeoFields(field) {
    return seoFields[field][language] ?? seoFields[field].ro // return ro fields if current language does not have them
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant" // or "instant"
    });
  }, []);

  return (
    <Layout>
      <HomePageHeader location={location} />
      <HomePageBrochure />
      <HomePageHeaderAdvertisement />
      <HomePageConfigSection location={location} />
      <SeoPage keyword={getSeoFields("keyword")} slug={getSeoFields("slug")} />
      <HomePageOppportunitiesSection />
      <VideoPageListing data={videos} location="homepage" />
      <HomepageNewServiceSection />
      <ArticlesSection />
      <GoogleReviewsSection />
      <FaqSection />
      <PartnersSection />
      <Footer location={location} />
      <PopUp />
      <CasaVerdePopup />
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default IndexPage
